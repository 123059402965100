import * as Sentry from '@sentry/nuxt';

const runtimeConfig = useRuntimeConfig();

const deployEnv = runtimeConfig.public.DEPLOY_ENV;
const version = runtimeConfig.public.VERSION;

if (deployEnv !== 'dev') {
    // TODO: the upgrade from Nuxt 3.14.159 to 3.15.1 caused a type error on Sentry.init, need to fix?
    // @ts-ignore-next-line
    Sentry.init({
        dsn: 'https://afc910cad5ea48348b845b69e0805ba0@o161782.ingest.us.sentry.io/4504714004856832',
        beforeSend(event: any) {
            if (event?.message === 'Error: ResizeObserver loop completed with undelivered notifications.') {
                return null;
            }
            return event;
        },
        environment: deployEnv,
        release: 'es-cms-articles@' + version,
    });
}
